import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "../../styles/faqs.css";
import {
  FadeAnimate,
  FadeBottomAnimate,
} from "../../components/animateEffect/Fade";
import { IphoneLink, convertStringToHTML } from "../../lib/config";
import { TextToHtmlComponent, parseStringToHtml } from "../../lib/html-parser";

const faqsData = [
  {
    question: "Who is the best drawing app?",
    answer: `<p>Best Drawing Apps and Software in 2024 (Free &amp; Paid)</p>
    <p><strong>iPhone </strong>: <a href=${IphoneLink} target="_blank" >${IphoneLink}</a></p>
    <p><strong>Android </strong>: <a target="_blank" href="https://play.google.com/store/apps/details?id=com.mitra.sketch.ar.drawing.paint&referrer=utm_source%3Dardrawing.app%26utm_medium%3Dreferral%26utm_campaign%3Dwebsite_promotion">https://play.google.com/store/apps/details?id=com.mitra.sketch.ar.drawing.paint&referrer=utm_source%3Dardrawing.app%26utm_medium%3Dreferral%26utm_campaign%3Dwebsite_promotion</a></p>`,
  },
  {
    question: "It's App Free?",
    answer: "yes , is free app",
  },
  {
    question: "Can I Share My Drawing Images or Video?",
    answer: "Yes, AR Drawing - Sketches App to Share Your Video Or Images.",
  },
  {
    question: "Can I Draw my own images or Pictures?",
    answer: `<p>Yes. You Can Draw it!</p>
    <p><strong>1.</strong> Upload good quality image.</p>
    <p><strong>2.</strong> Make image</p>
    <p><strong>3.</strong> Adjust image &amp; create traceable.</p>
    <p><strong>4.</strong> Place your phone on Tripod or cup above page.</p>
    <p><strong>5</strong>. Quick draw and create an art.</p>
    `,
  },
  {
    question: "Can I Record My Drawing Video? ",
    answer: "yes , You Can Record it.",
  },
  {
    question: "Can I print the AR Drawings created with a AR Drawing maker?",
    answer:
      "Yes, you can print the AR Drawings created using AR Drawing. After designing your AR Drawing, you can save it as a high-resolution file and then print it using a compatible printer.",
  },
  {
    question: "Can I make changes to my AR Drawing after saving it?",
    answer:
      "Yes, AR Drawing maker allows you to edit and modify your saved AR Drawings.",
  },
  {
    question: "Can I download my AR Drawings in different file formats?",
    answer:
      "Yes, AR Drawing makers offer the ability to download your AR Drawings in various file formats such as PNG, JPEG, and PDF. This allows you to choose the format that suits your needs for printing or digital sharing.",
  },
  {
    question: "Can I save my AR Drawing as a template for future use?",
    answer:
      "Yes, AR Drawing makers provide the option to save your AR Drawing as a template for future use. This allows you to reuse the design and make quick modifications for similar projects, saving you time and effort in creating new AR Drawings from scratch.",
  },
  {
    question: "Can I share my AR Drawings online?",
    answer:
      "Yes, you can share your AR Drawings online. AR Drawing Maker provides options to save your AR Drawings in digital formats, such as PNG, JPEG, or PDF, which can be easily shared via email, social media, or websites.",
  },
];

const Faqs = () => {
  return (
    <div className="container sectionVerticalSpace">
      <FadeAnimate>
        <h1 className="faq-header header-title text-center sectionTitleBS mb-0">
          FAQs
        </h1>
      </FadeAnimate>
      
      <Accordion defaultActiveKey="0">
        {faqsData.map((faq, index) => (
          <FadeBottomAnimate>
            <Accordion.Item key={index} eventKey={index.toString()}>
              <Accordion.Header>{faq.question}</Accordion.Header>
              <Accordion.Body><TextToHtmlComponent text={faq.answer} /></Accordion.Body>
            </Accordion.Item>
          </FadeBottomAnimate>
        ))}
      </Accordion>
    </div>
  );
};

export default Faqs;

import { Link } from "react-router-dom";
import * as Config from "../lib/config";
import "../styles/footer.css";
import Image from "../components/Image";
import { Link as ScrollLink } from "react-scroll";
import { RedirectLink } from "../components/redirectLink/redirect";

const Footer = () => {
  return (
    <>
      <footer className="footer footerSection mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4 d-flex justify-content-md-left">
              <div>
                <h2>ARDrawing.app</h2>
                <div className="app-links d-flex">
                  <div className="pr-1">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.mitra.sketch.ar.drawing.paint&referrer=utm_source%3Dardrawing.app%26utm_medium%3Dreferral%26utm_campaign%3Dwebsite_promotion"
                    >
                      <Image
                        src={Config.images.playstore}
                        alt="Download on Google Play"
                        className="footerShareIcons"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={Config.IphoneLink}
                    >
                      <Image
                        src={Config.images.appstore}
                        alt="Download on the App Store"
                        className="footerShareIcons"
                      />
                    </a>
                  </div>
                </div>
                <p className="appText">© ARDrawing@2024. All rights reserved</p>
              </div>
            </div>
            <div className="col-md-2 d-flex justify-content-md-center">
              <div>
                <h2>Quick Links</h2>
                <p>
                  <ScrollLink
                    className="cursor-pointer"
                    to="Features"
                    smooth={true}
                    duration={100}
                  >
                    Features
                  </ScrollLink>
                </p>
                <p>
                  <ScrollLink
                    className="cursor-pointer"
                    to="Templates"
                    smooth={true}
                    duration={100}
                  >
                    Templates
                  </ScrollLink>
                </p>
                <p>
                  <ScrollLink
                    className="cursor-pointer"
                    to="Reviews"
                    smooth={true}
                    duration={100}
                  >
                    Reviews
                  </ScrollLink>
                </p>
                <p>
                  {/* <Link to="#">Learn</Link> */}
                </p>
              </div>
            </div>
            <div className="col-md-3 d-flex justify-content-md-center">
              <div>
                <h2>Legal</h2>
                <p>
                  <a
                    href="https://www.devkrushna.com/policy/privacypolicy.html"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </p>
                <p>
                  <a
                    href="https://devkrushnaapps.blogspot.com/"
                    target="_blank"
                  >
                    Terms of use
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-3 ">
              <div>
                <h2>Social Links</h2>
                <div className="social-links d-flex">
                  <a
                    href="https://www.youtube.com/@ARDrawingSketches"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={Config.socialIcons.youtubeIcon}
                      alt="img-youtubeIcon"
                      name="youtubeIcon"
                      className="img-fuild sociallinksIcon"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/ar_drawingapp/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={Config.socialIcons.InstagramLogo}
                      alt="img-rightIcon"
                      name="rightIcon"
                      className="img-fuild sociallinksIcon ml-3"
                    />
                  </a>
                  {/* <Link to="#">
                  <Image
                    src={Config.socialIcons.twitterIcon}
                    alt="img-rightIcon"
                    name="rightIcon"
                    className="img-fuild"
                  />
                </Link>
                <Link to="#">
                  <Image
                    src={Config.socialIcons.LinkedInLogo}
                    alt="img-rightIcon"
                    name="rightIcon"
                    className="img-fuild"
                  />
                </Link>
                <Link to="#">
                  <Image
                    src={Config.socialIcons.PinterestLogo}
                    alt="img-rightIcon"
                    name="rightIcon"
                    className="img-fuild"
                  />
                </Link> */}
                </div>
              </div>
            </div>
            {/* <div className="col-md-2">
              <h2>Tools</h2>
              <p>
                <Link to="#">QR Code Generator</Link>
              </p>
              <p>
                <Link to="#">Color Palettes</Link>
              </p>
              <p>
                <Link to="#">Color Palette Generator (Coming Soon)</Link>
              </p>
              <p>
                <Link to="#">Color Contrast Checker (Coming Soon)</Link>
              </p>
              <p>
                <Link to="#">Font Pairing Tool</Link>
              </p>
            </div> */}
          </div>
          <div className="row pt-md-0 pt-4">
            <div className="col text-center">
              <RedirectLink>
                <button className="btn">Make Your Own AR Drawing</button>
              </RedirectLink>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

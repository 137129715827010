import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { IphoneLink } from "../../lib/config";

export const RedirectLink = ({ children }) => {
  // const [isAndroid, setIsAndroid] = useState(false);
  const [isiPhone, setIsiPhone] = useState(false);
  // console.log("🚀 ~ file: home.jsx:14 ~ Home ~ isAndroid:", isAndroid, isiPhone)

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    setIsiPhone(
      userAgent.indexOf("iphone") > -1 ||
        userAgent.indexOf("ipad") > -1 ||
        userAgent.indexOf("ipod") > -1
    );
    // setIsAndroid(userAgent.indexOf('android') > -1);
  }, []);
  return (
    <a
      href={
        isiPhone
          ? IphoneLink
          : "https://play.google.com/store/apps/details?id=com.mitra.sketch.ar.drawing.paint&referrer=utm_source%3Dardrawing.app%26utm_medium%3Dreferral%26utm_campaign%3Dwebsite_promotion"
      }
      target="_blank"
      rel="appLinks"
      className="redirectLink"
    >
      {children}
    </a>
  );
};

// Section5.js
import React from "react";
import "../../styles/section5.css";
import "../../styles/reviewSlider.css";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "../../components/Image";
import CustomSlider from "../../components/reactSlider/slider";
import OwnDrawingBtn from "./OwnDrawingBtn";
import ReviewCard from "./ReviewCard"; // Import the new component
import { Fade } from "react-reveal";
import { RedirectLink } from "../../components/redirectLink/redirect";
import { Icons, IphoneLink, personProfile } from "../../lib/config";

const Settings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1540,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

const reviewsData = [
  {
    username: "momellyville",
    rating: 5,
    review:
      "Had a seamless experience using this for multiple AR Drawings. Looking forward to using the Pro Version.",
    imageUrl: personProfile.profile1,
  },
  {
    username: "momellyville",
    rating: 5,
    review:
      "Had a seamless experience using this for multiple AR Drawings. Looking forward to using the Pro Version.",
    imageUrl: personProfile.profile2,
  },
  {
    username: "momellyville",
    rating: 5,
    review:
      "Had a seamseamless experience using thisseamless experience using thisseamless experience using thisless experience using this for multiple AR Drawings. Looking forward to using the Pro Version.",
    imageUrl: personProfile.profile3,
  },
  {
    username: "momellyville",
    rating: 5,
    review:
      "Had a seamless experience using this for multiple AR Drawings. Looking forward to using the Pro Version.",
    imageUrl: personProfile.profile4,
  },
  {
    username: "momellyville",
    rating: 5,
    review:
      "Had a seamless experience using this for multiple AR Drawings. Looking forward to using the Pro Version.",
    imageUrl: personProfile.profile5,
  },
  {
    username: "momellyville",
    rating: 5,
    review:
      "Had a seamless experience using this for multiple AR Drawings. Looking forward to using the Pro Version.",
    imageUrl: personProfile.profile1,
  },
  // Add more reviews as needed
];

const Section5 = () => {
  return (
    <>
      <div
        className="container section5Container sectionVerticalSpace"
        id="Reviews"
      >
        <div className="text-center sectionTitleBS">
          <Fade>
            <h1 className="header-title">AR Drawing Maker Reviews</h1>
          </Fade>
        </div>
        <div className="row">
          <div className="col-md-3 d-flex flex-column justify-content-center">
            <a
              target="_blank"
              className="redirectLink"
              rel="noreferrer"
              href={IphoneLink}
            >
              <Fade bottom>
                <div className="store-rating position-relative d-flex">
                  <div>
                    <Image
                      alt="App Store logo placeholder"
                      src={Icons.App_Store_Icon}
                    />
                  </div>
                  <div className="px-3 text-left">
                    <div className="storeName">App Store</div>
                    <div className="d-flex">
                      <span className="rateNumber">4.8</span>
                      <div className="starsSection">
                        <FontAwesomeIcon icon={faStar} className="rate-star" />
                        <FontAwesomeIcon icon={faStar} className="rate-star" />
                        <FontAwesomeIcon icon={faStar} className="rate-star" />
                        <FontAwesomeIcon icon={faStar} className="rate-star" />
                        <FontAwesomeIcon icon={faStar} className="rate-star" />
                      </div>
                    </div>
                    <span
                      className="text-decoration-none text-primary allreviews"
                      href="#"
                    >
                      See all reviews
                    </span>
                  </div>
                </div>
              </Fade>
            </a>
          </div>
          <div className="col-md-9 slick_Arrow pl-4 pl-md-5 pt-4 pt-md-0">
            <CustomSlider settings={Settings}>
              {reviewsData.map((review, index) => (
                <Fade bottom>
                  <ReviewCard key={index} {...review} />
                </Fade>
              ))}
            </CustomSlider>
          </div>
        </div>
        <div className="row pt-4 pt-md-0">
          <div className="col-md-3 d-flex flex-column justify-content-center">
            <a
              target="_blank"
              rel="noreferrer"
              className="redirectLink"
              href="https://play.google.com/store/apps/details?id=com.mitra.sketch.ar.drawing.paint&referrer=utm_source%3Dardrawing.app%26utm_medium%3Dreferral%26utm_campaign%3Dwebsite_promotion "
            >
              <Fade bottom>
                <div className="store-rating position-relative d-flex">
                  <Image
                    alt="Google Play Store logo placeholder"
                    src={Icons.Google_Play_icon}
                  />
                  <div className="text-left px-3">
                    <div className="storeName">Google Play Store</div>
                    <div className="d-flex">
                      <span className="rateNumber">4.7</span>
                      <div className="starsSection">
                        <FontAwesomeIcon icon={faStar} className="rate-star" />
                        <FontAwesomeIcon icon={faStar} className="rate-star" />
                        <FontAwesomeIcon icon={faStar} className="rate-star" />
                        <FontAwesomeIcon icon={faStar} className="rate-star" />
                        <FontAwesomeIcon icon={faStar} className="rate-star" />
                      </div>
                    </div>
                    <span
                      className="text-decoration-none text-primary allreviews"
                      href="#"
                    >
                      See all reviews
                    </span>
                  </div>
                </div>
              </Fade>
            </a>
          </div>
          <div className="col-md-9 slick_Arrow pl-4 pl-md-5 pt-4 pt-md-0">
            <CustomSlider settings={Settings}>
              {reviewsData.map((review, index) => (
                <Fade bottom>
                  <ReviewCard key={index} {...review} />
                </Fade>
              ))}
            </CustomSlider>
          </div>
        </div>
        <OwnDrawingBtn />
      </div>
    </>
  );
};

export default Section5;
